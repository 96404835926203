import React from "react";
// import classNames from "classnames";
import { findMatchingThreshold } from "../../../../helpers/brag-helpers";
// ? TYPES:
import { CSSProperties } from "react";
import { FormikIndicatorInputProps } from "../types";

export const IndicatorInputSwitch: React.FC<FormikIndicatorInputProps> = ({
  _name,
  handleChange,
  indicator,
  index,
  field,
}) => {
  const id = _name + "_" + index;
  const found = field.value[index] || { value: 0 };
  const inputColorOff = indicator && findMatchingThreshold(indicator, 0);
  const inputColorOn = indicator && findMatchingThreshold(indicator, 1);
  const style = {
    "--switch-colour-on": inputColorOn?.colorCodeBg,
    "--switch-colour-off": inputColorOff?.colorCodeBg,
  } as CSSProperties;
  return (
    <div className="switch-control__wrapper switch-control__wrapper--brag" style={style}>
      <div className="switch-control">
        <input
          type="checkbox"
          name={id}
          checked={found.value === 1}
          onChange={(e) => handleChange({ value: e.target.checked ? 1 : 0 })}
        />
        <em></em>
      </div>
    </div>
  );
};
