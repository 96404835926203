// ? TYPES:
import { ImportedConfigIndicator } from "../../../types/forms-config";

function getLetterByNumber(n: number, isUppercase = false): string {
  const startCharCode = isUppercase ? 65 : 97; // 65 for 'A', 97 for 'a'
  return String.fromCharCode(startCharCode + n - 1);
}

export function getIndicatorCommentMeta(configIndicators: ImportedConfigIndicator[]) {
  return configIndicators.reduce(
    (acc, indicator, i) => {
      if (indicator.isEditable) {
        acc.editableCount = +1;
      }
      if (indicator.isCommentEnabled === true) {
        acc.commentCount += 1;
        acc.commentFootLabelPlot[i] = getLetterByNumber(acc.commentCount);
      }
      return acc;
    },
    {
      commentCount: 0,
      editableCount: 0,
      commentFootLabelPlot: {},
      commentFootnotesEnabled: configIndicators.length - 1 !== 0,
    } as {
      commentCount: number;
      editableCount: number;
      commentFootLabelPlot: Record<number, string>;
      commentFootnotesEnabled: boolean;
    }
  );
}
