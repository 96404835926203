import React from "react";
import { Field } from "formik";
import { Input, Checkbox, Select as ReactSelect } from "@tscore/react-components";
import { SimpleRadio as Radio } from "./simple-radio";
// ? TYPES:
import { ImportedConfigOption, ConfigOption } from "../../types/forms-config";

// const NEW_BRAG_TOOLTIP_TEXT = "Please enter new indicator values in the fields provided";
// const INDICATOR_VALUE_MISSING_TOOLTIP_TEXT = "Indicator value unavailable";
const generateOptions = (options: ImportedConfigOption[]): ConfigOption[] => {
  return options.reduce((final: ConfigOption[], item: ImportedConfigOption, index: number) => {
    final[index] = {
      value: item.value,
      label: item.title,
    };
    return final;
  }, []);
};

export const FormikInput = (props: any): JSX.Element => {
  return (
    <Field
      name={props._name || props.name}
      render={({ field }: any) => <Input {...field} {...props} _name={undefined} />}
    />
  );
};

export const FormikTextArea = (props: any): JSX.Element => {
  return (
    <Field
      name={props._name}
      render={({ field }: any) => <textarea placeholder={props.placeholder} className="input" {...field} />}
    />
  );
};

export const FormikSelect = (props: any): JSX.Element => {
  const options: ConfigOption[] = generateOptions(props.config.options);
  const settings: any = {
    isMulti: props.config.allowMultiSelect ? true : false,
  };
  const getValue = (
    value: string | string[],
    isMulti: boolean,
    options: ConfigOption[]
  ): ConfigOption | ConfigOption[] | undefined => {
    if (!isMulti) {
      return options.find((v: ConfigOption) => v.value === value[0]) || [];
    }
    return options.reduce((final: any[], v: ConfigOption) => {
      if (!!value && value.includes(v.value as string)) {
        final.push(v);
      }
      return final;
    }, []);
  };
  const handleChange = (selectedOption: any | any[]): void => {
    if (!settings.isMulti) {
      // props.setFieldValue(props._name, selectedOption.value);
      props.setFieldValue(props._name, [selectedOption.value]);
    } else {
      // array
      props.setFieldValue(
        props._name,
        selectedOption === null ? [] : selectedOption.map((option: ConfigOption) => option.value)
      );
    }
  };
  return (
    <Field
      name={props._name}
      render={({ field }: any) => (
        <ReactSelect
          value={getValue(field.value, settings.isMulti, options)}
          classNamePrefix="select"
          className="select"
          options={options}
          onChange={handleChange}
          placeholder={props.placeholder}
          {...settings}
        />
      )}
    />
  );
};

const FormikCheckbox = (props: any): JSX.Element => {
  const { setFieldValue } = props;
  const handleChange = (value: string[]): void => {
    // value can be number also
    if (value.includes(props.value)) {
      const nextValue = value.filter((v: string) => v !== props.value);
      setFieldValue(props._name, nextValue);
    } else {
      const nextValue = value.concat(props.value);
      setFieldValue(props._name, nextValue);
    }
  };
  return (
    <Field
      name={props._name}
      render={({ field }: any) => {
        return (
          <Checkbox
            name={props._name + "_" + props.value}
            checked={field.value.includes(props.value)}
            onChange={() => handleChange(field.value)}>
            {props.label}
          </Checkbox>
        );
      }}
    />
  );
};

export const FormikCheckboxGroup = (props: any): JSX.Element => {
  return props.config.options.map((option: any) => (
    <FormikCheckbox
      key={props._name + "_" + option.value}
      name={props._name}
      label={option.title}
      value={option.value}
      {...props}
    />
  ));
};

const FormikRadio = (props: any): JSX.Element => {
  const { setFieldValue } = props;
  const handleChange = (value: string | number): void => {
    setFieldValue(props._name, [value]);
  };
  return (
    <Field
      name={props._name}
      render={({ field }: any) => {
        return (
          <Radio
            data-name={props._name + "." + props.value}
            checked={field.value.includes(props.value)}
            onChange={() => handleChange(props.value)}>
            {props.label}
          </Radio>
        );
      }}
    />
  );
};

export const FormikRadioGroup = (props: any): JSX.Element => {
  return props.config.options.map((option: any) => (
    <FormikRadio
      key={props._name + "_" + option.value}
      name={props._name}
      label={option.title}
      value={option.value}
      {...props}
    />
  ));
};
