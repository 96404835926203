import React from "react";
import { useField } from "formik";
import { Tippy } from "@tscore/react-components";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import { IndicatorInputNumeric } from "./indicator-input-numeric";
import { IndicatorInputDate } from "./indicator-input-date";
import { IndicatorInputSwitch } from "./indicator-input-switch";
// ? TYPES:
// import IndicatorNew from "../../../../types/indicator-new";
import { ConfigIndicator, ImportedConfigIndicator } from "../../../../types/forms-config";
import { FormikIndicatorInputProps, IndicatorUpdateFunction } from "../types";
import { TFunction } from "i18next";
import { IndicatorRegionReport } from "../../../../types/indicator";

const WhichIndicatorInput: React.FC<FormikIndicatorInputProps & { option: ImportedConfigIndicator }> = ({
  option,
  ...props
}) => {
  if (props.indicator.dataLabel === 10) {
    return <IndicatorInputDate {...props} />;
  }
  if (option.inputType === "TOGGLE") {
    return <IndicatorInputSwitch {...props} />;
  }
  return <IndicatorInputNumeric {...props} />;
};

const IndicatorInputWoT = ({
  handleChange,
  isEditable,
  shouldAddTooltip,
  indicator,
  index,
  _name,
  option,
  t,
}: {
  name: string;
  _name: string;
  handleChange: ReturnType<IndicatorUpdateFunction>;
  isEditable: boolean;
  shouldAddTooltip: boolean;
  option: ImportedConfigIndicator;
  index: number;
  indicator: IndicatorRegionReport;
  t: TFunction;
}): JSX.Element => {
  const [field] = useField<ConfigIndicator[]>({ name: _name });
  return (
    <span
      className={classNames("cell", "brag-holder", { "empty-indicator": !isEditable })}
      style={{ position: shouldAddTooltip ? "relative" : undefined }}>
      {isEditable && (
        <WhichIndicatorInput
          option={option}
          _name={_name}
          indicator={indicator}
          index={index}
          handleChange={handleChange(field.value)}
          field={field}
        />
      )}
      {shouldAddTooltip && (
        <Tippy content={t("tooltipNewBragText")} placement="bottom">
          <span className="tooltip-for-brags">
            <i className="material-icons color-blue">info</i>
          </span>
        </Tippy>
      )}
    </span>
  );
};

export const IndicatorInput = withTranslation(["regionReports"])(IndicatorInputWoT);
