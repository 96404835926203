import moment from "moment";

export const timeFormat = "HH:mm";
export const dateFormat = "dd/MM/yyyy";
export const timeDateFormat = `${dateFormat} ${timeFormat}`;

export const momentTimeFormat = "LT";
export const momentDateFormat = "L";
export const momentTimeDateFormat = `${momentDateFormat} ${momentTimeFormat}`;
export const momentUpdatedTimeDateFormat = `${momentTimeFormat}, DD MMM YYYY`;
export const DATE_FILTER_FORMAT = "DD-MM-YYYY";

export const DATE_ICONS: any = {
  date: "calendar_today",
  datetime: "date_range",
  time: "access_time",
};

export const formatToDate = (date: Date | string | undefined, format?: string): Date | undefined => {
  if (!date || date === "") {
    return undefined;
  }
  if (format) {
    return moment(date, format).toDate();
  }
  return moment(date).toDate();
};

export const formatToString = (date: Date | string | undefined | null, format: string, defaultValue = ""): string => {
  if (!date || date === null) {
    return defaultValue;
  }
  return moment(date).format(format);
};

export const formatToStringByFormat = (
  date: Date | string | undefined,
  inputFormat: string,
  format: string
): string => {
  if (!date || date === null) {
    return "";
  }
  return moment(date, inputFormat).format(format);
};

export const formatToISO = (date: Date | any): string => {
  // return date.toISOString();
  // YYYY-MM-DDTHH:mm:ssZ
  // return moment(date).format();
  return moment(date).milliseconds(0).toISOString().replace(/.000Z/, "Z");
  // remove milliseconds
};

export const formatToISOFromFilter = (
  dateStr: Date | string | undefined,
  type: "start" | "end" = "start",
  format = DATE_FILTER_FORMAT
) => {
  const setOptions =
    type === "start"
      ? { hour: 0, minute: 0, second: 0, millisecond: 0 }
      : { hour: 23, minute: 59, second: 59, millisecond: 0 };
  if (!dateStr) {
    return undefined;
  }
  return formatToISO(moment(dateStr, format).set(setOptions));
};

export const formatDateToUnixMs = (date: Date, { startOf = "day" }: { startOf?: "day" } = {}): UnixTimestampMs => {
  if (!startOf) {
    // return moment(date).toDate().getTime();
    return moment.utc(date).milliseconds(0).toDate().getTime();
  }
  // return moment(date).startOf(startOf).toDate().getTime();
  return moment.utc(date).milliseconds(0).startOf(startOf).toDate().getTime();
};

const getMsOffset = () => {
  const d = new Date();
  return d.getTimezoneOffset() * 60000;
  // return (
  //   moment(d).milliseconds(0).startOf("day").toDate().getTime() -
  //   moment.utc(d).milliseconds(0).startOf("day").toDate().getTime()
  // );
};

export const MS_OFFSET = getMsOffset();

export const selectedUnixUTCMsToLocalTimeZoneDate = (ms?: UnixTimestampMs): Date | undefined => {
  if (!ms) {
    return ms as undefined;
  }
  return new Date(ms - MS_OFFSET);
};
