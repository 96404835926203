import React from "react";
import { useField } from "formik";
import { INPUT_AUTOCOMPLETE_OFF_VALUE } from "../../../globals/settings";
import { IndicatorFootnoteLabel } from "./indicator-footnote-label";
import { withTranslation } from "react-i18next";
// ? TYPES:
import { ConfigIndicator } from "../../../types/forms-config";
import { IndicatorUpdateFunction } from "./types";
import { TFunction } from "i18next";

const IndicatorCommentInputWoT: React.FC<{
  _name: string;
  index: number;
  handleChange: ReturnType<IndicatorUpdateFunction>;
  // option: ImportedConfigIndicator;
  footLabel: string | undefined;
  t: TFunction;
  [k: string]: unknown;
}> = ({ handleChange, index, footLabel, _name, t }) => {
  const [field] = useField<ConfigIndicator[]>({ name: _name });
  const found = field.value[index] || {};
  const currentValue = found.comment === undefined ? { comment: "" } : found;
  const id = field.name + "_" + index;
  return (
    <div className="indicator__footnote">
      <IndicatorFootnoteLabel footLabel={footLabel} id={id} />
      <input
        type="text"
        className="input input-indicator-comment"
        {...field}
        name={id}
        id={id}
        onChange={(event: any) => handleChange(field.value)({ comment: event.target.value })}
        value={currentValue.comment}
        autoComplete={INPUT_AUTOCOMPLETE_OFF_VALUE}
        placeholder={t("indicatorCommentPlaceholder")}
        maxLength={500}
      />
    </div>
  );
};

export const IndicatorCommentInput = withTranslation(["regionReports"])(IndicatorCommentInputWoT);
