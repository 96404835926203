import React, { forwardRef } from "react";
import { DatePicker } from "@tscore/react-components";
import { formatDateToUnixMs, selectedUnixUTCMsToLocalTimeZoneDate } from "../../../../lang/DateTimeFormats";
// ? TYPES:
import { FormikIndicatorInputProps } from "../types";

const CustomValueDatePicker = forwardRef(({ onClick, value, onChange, type, ...props }: any, ref: any) => (
  <div ref={ref} className={"icon-input report-date-input"} onClick={onClick}>
    <input
      type="text"
      autoComplete={false}
      {...props}
      onChange={onChange}
      value={value}
      aria-autocomplete="none"
      className={`input input-brag${value === "" ? "" : " brag-blue"}`}
    />
    {/* <i style={{ transform: "translate(-50%,-50%)", left: "50%" }} className="icon material-icons">
      calendar_today
    </i> */}
  </div>
));
export const IndicatorInputDate: React.FC<FormikIndicatorInputProps> = ({ _name, field, index, handleChange }) => {
  return (
    <div>
      <DatePicker
        // popperPlacement="top-end"
        selected={selectedUnixUTCMsToLocalTimeZoneDate((field.value[index] || {}).value as UnixTimestampMs)}
        onChange={(date: any) => {
          const hack = date.getHours() === 0 ? new Date(date.getTime() - date.getTimezoneOffset() * 60000) : date;
          handleChange({ value: formatDateToUnixMs(hack) });
        }}
        // filterDate={(date: any) => {
        //   return new Date() > date;
        // }}
        customInput={
          <CustomValueDatePicker
            style={{
              maxWidth: "60px",
              padding: "0 0.1rem",
              fontSize: "10px",
            }}
          />
        }
        name={_name + "_" + index}
        placeholderText=" "
      />
    </div>
  );
};
