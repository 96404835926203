import React from "react";
import { IndicatorTitle } from "./indicator-title";
import { IndicatorBragRow } from "./indicator-brag-row";
import { IndicatorInput } from "./indicator-input";
import { IndicatorCommentInput } from "./indicator-comment-input";
import { calculateNewIndicatorColourByThreshold, PRESSURE_LEVEL_BRAG_MAPPING } from "../../../helpers/brag-helpers";
import { getIndicator } from "../../../helpers/report-helpers";
import { getIndicatorCommentMeta } from "./utils";
// ? TYPES:
import { ImportedConfigIndicator, ConfigIndicator } from "../../../types/forms-config";
import { CurrentAndPastIndicatorStatus } from "../../../types/forms/general";
import { IndicatorUpdateFunction } from "./types";

export const FormikIndicatorGroup = (props: {
  config: { indicators: ImportedConfigIndicator[]; [k: string]: unknown };
  indicators: CurrentAndPastIndicatorStatus;
  _name: string;
  [k: string]: any;
}): JSX.Element => {
  const { commentCount, editableCount, commentFootLabelPlot, commentFootnotesEnabled } = getIndicatorCommentMeta(
    props.config.indicators
  );

  const handleChange: IndicatorUpdateFunction = (option, index) => (value) => (newPayload) => {
    const indicator = getIndicator(option, props.indicators);
    if (!indicator) {
      return;
    }
    const previousValue = value[index];
    const color = calculateNewIndicatorColourByThreshold(indicator, newPayload.value || previousValue.value);
    const updating: { value?: true; comment?: true } = Object.keys(newPayload).reduce(
      (acc, name) => ({ ...acc, [name]: true }),
      {}
    );
    // is Data type needed?
    const newValue: ConfigIndicator = {
      ...previousValue,
      indicatorId: indicator.indicatorId,
      pressureLevel: PRESSURE_LEVEL_BRAG_MAPPING[color as string] ? PRESSURE_LEVEL_BRAG_MAPPING[color as string] : null,
      displayOption: indicator.displayOption,
      dataLabel: indicator.dataLabel,
      valueType: indicator.valueType,
      ...(updating.value && { value: newPayload.value !== "" ? parseFloat(newPayload.value as string) : "" }),
      ...(updating.comment && { comment: newPayload.comment }),
    };
    if (updating.comment && !newPayload.comment) {
      delete newValue.comment;
    }
    props.setFieldValue(props._name, Object.assign([...value], { [index]: newValue }));
    // setFieldValue(props._name, value);
  };
  const lastIndex = props.config.indicators.length - 1;
  return (
    <>
      <div className="table main bordered no-horizontal-lines" style={{ width: "auto" }}>
        <IndicatorTitle
          name={props._name}
          configIndicators={props.config.indicators}
          commentFootLabelPlot={commentFootnotesEnabled ? commentFootLabelPlot : {}}
        />
        <div className="tbody">
          <IndicatorBragRow
            showReadableValues={props.showReadableValues}
            configIndicators={props.config.indicators}
            indicators={props.indicators}
          />
          {editableCount > 0 && (
            <div className="row">
              {/* {areAnyEditable && <span className="test">new value</span>} */}
              {props.config.indicators.map((option: ImportedConfigIndicator, index: number) => {
                return (
                  <IndicatorInput
                    option={option}
                    shouldAddTooltip={index === lastIndex}
                    key={props._name + "_" + index}
                    index={index}
                    name={props._name}
                    indicator={getIndicator(option, props.indicators)!}
                    // option={option}
                    isEditable={option.isEditable}
                    handleChange={handleChange(option, index)}
                    {...props}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
      {commentCount > 0 && (
        <div className="__footnote__notch">
          <div>
            <footer className="indicator__footnotes">
              {props.config.indicators.map((option: ImportedConfigIndicator, index: number) => {
                const footLabel = commentFootLabelPlot[index];
                if (!footLabel) {
                  return null;
                }
                return (
                  <IndicatorCommentInput
                    key={props._name + "_" + index}
                    footLabel={commentFootnotesEnabled ? commentFootLabelPlot[index] : undefined}
                    _name={props._name}
                    index={index}
                    handleChange={handleChange(option, index)}
                  />
                );
              })}
            </footer>
          </div>
        </div>
      )}
    </>
  );
};
