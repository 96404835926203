import React from "react";
import classNames from "classnames";
import { calculateNewIndicatorColourByThreshold } from "../../../../helpers/brag-helpers";
// ? TYPES:
import { FormikIndicatorInputProps } from "../types";

export const IndicatorInputNumeric: React.FC<FormikIndicatorInputProps> = ({
  _name,
  handleChange,
  indicator,
  index,
  field,
}) => {
  const found = field.value[index] || { value: "" };
  const inputColor = indicator && calculateNewIndicatorColourByThreshold(indicator, found.value);
  return (
    <input
      aria-autocomplete="none"
      name={_name + "_" + index}
      type="number"
      onChange={(event: any) => handleChange({ value: event.target.value })}
      placeholder=""
      value={found.value}
      className={classNames("input", "input-brag", { ["brag-" + inputColor]: !!inputColor })}
      style={{ maxWidth: "60px" }}
    />
  );
};
