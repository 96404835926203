import React from "react";

export const IndicatorFootnoteLabel: React.FC<{ footLabel: string | undefined; enabled?: boolean; id?: string }> = ({
  footLabel,
  id,
  enabled = true,
}) => {
  if (!enabled || !footLabel) {
    return null;
  }
  return (
    <label className="indicator__footnote__label" htmlFor={id}>
      {footLabel}
    </label>
  );
};
