import React from "react";
import { Icon, Tippy } from "@tscore/react-components";
import classNames from "classnames";
import { getIndicator } from "../../../helpers/report-helpers";
import { calculateNewIndicatorColourByThreshold } from "../../../helpers/brag-helpers";
import { withTranslation } from "react-i18next";
// ? TYPES:
import { ImportedConfigIndicator } from "../../../types/forms-config";
import { CurrentAndPastIndicatorStatus } from "../../../types/forms/general";
// import IndicatorNew from "../../../types/indicator-new";
import { TFunction } from "i18next";
import { IndicatorRegionReport } from "../../../types/indicator";

const IndicatorBragRowWot = ({
  configIndicators,
  indicators,
  showReadableValues,
  t,
}: {
  configIndicators: ImportedConfigIndicator[];
  indicators: CurrentAndPastIndicatorStatus;
  showReadableValues: boolean;
  t: TFunction;
}): JSX.Element => {
  return (
    <div className="row">
      {configIndicators.map((option: ImportedConfigIndicator, index: number) => {
        const found = getIndicator(option, indicators);
        const indicator = found || {
          value: "-",
          readableValue: "-",
        };
        // let bragProps: any = {};
        let color = null;
        if (found) {
          // bragProps = generateBragStylesOrClasses(indicator.colorCodeFg, indicator.colorCodeBg, "brag");
          color = calculateNewIndicatorColourByThreshold(indicator as IndicatorRegionReport, indicator.value);
        }
        const v = showReadableValues ? indicator.readableValue || indicator.value : indicator.value;
        // const isPlainNumber = showReadableValues ? /^[0-9,.]*$/.test(indicator.readableValue || "") : false;
        const value = indicator.value !== null ? v : "-";
        return (
          <span key={option.indicatorId + "_" + index} className="cell brag-holder" style={{ position: "relative" }}>
            <span className={classNames("brag", { ["brag-" + color]: !!color }, { "brag-white": !color })}>
              {value}
              {option.suffix}
            </span>
            {value === "-" && (
              <Tippy content={t("tooltipIndicatorValueMissingText")} placement="bottom">
                <span className="tooltip-inside-brag">
                  <Icon className="color-blue">help</Icon>
                </span>
              </Tippy>
            )}
          </span>
        );
      })}
    </div>
  );
};

export const IndicatorBragRow = withTranslation(["regionReports"])(IndicatorBragRowWot);
