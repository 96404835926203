import { simpleApi } from "../../../utils/simple-api";
// import Indicator from "types/indicator.type";
// import { generateApiUrl } from "./config";
import { SERVICE_URLS } from "../../../globals/service-urls";
import { generateParams } from "../../../helpers/generate-params";
// import { DEFAULT_PAGE_LIMIT } from "../../../globals/settings";
import {
  GET_API_VERSION,
  GET_REPORT_TYPE,
  GET_REPORT_TYPE_WITH_ID_STRING,
} from "../../../store/actions/reports/get-api-version";
import { SERVICE_NAME } from "./consts";
import { FormValues, ReportType } from "../../../types/forms/general";
import { ApiActionPayload, ApiCallback } from "../../../store/types/api";
import { RecentReport } from "../../../types/forms/recent-report";

type ReportTemplateResponse = Pick<RecentReport, "template"> & {
  isPreviousAnswersEnabled: boolean;
};

type ReportDataAndTemplate = RecentReport & {
  isPreviousAnswersEnabled: boolean;
};

const getTemplateByOrganisationIdAndType = (
  organisationId: string | number,
  type: ReportType,
  callbacks: ApiCallback<ReportTemplateResponse>
) => {
  const x = GET_REPORT_TYPE_WITH_ID_STRING(organisationId, type);
  const action: ApiActionPayload = {
    url: `${SERVICE_URLS[SERVICE_NAME]}${GET_API_VERSION(type)}/${x}/template`,
    method: "GET",
    ...callbacks,
  };
  simpleApi(action);
  return action;
};

const saveReport = (
  body: FormValues,
  organisationId: string | number,
  type: ReportType,
  reportId: string | number | null,
  callbacks: ApiCallback<{
    reportId: number;
    templateId: string;
    data: FormValues;
    additionalQuestions?: null;
  }>
) => {
  const x = GET_REPORT_TYPE_WITH_ID_STRING(organisationId, type);
  const params = {
    reportId: reportId ? reportId : undefined,
  };
  const action: ApiActionPayload = {
    url: `${SERVICE_URLS[SERVICE_NAME]}${GET_API_VERSION(type)}/${x}/save${generateParams(params)}`,
    method: "POST",
    data: { data: body },
    ...callbacks,
  };
  simpleApi(action);
  return action;
};

const saveAsDraft = (
  body: FormValues,
  organisationId: string | number,
  type: ReportType,
  reportId: string | number | null | undefined = undefined,
  callbacks: ApiCallback<{
    reportId: number;
    templateId: string;
    data: FormValues;
    additionalQuestions?: null;
  }>
) => {
  const x = GET_REPORT_TYPE_WITH_ID_STRING(organisationId, type);
  const params = {
    reportId: reportId ? reportId : undefined,
  };
  const action: ApiActionPayload = {
    url: `${SERVICE_URLS[SERVICE_NAME]}${GET_API_VERSION(type)}/${x}/draft${generateParams(params)}`,
    method: "POST",
    data: { data: body },
    ...callbacks,
  };
  simpleApi(action);
  return action;
};

const getReportDataAndTemplate = (
  reportId: string | number,
  type: ReportType,
  callbacks: ApiCallback<ReportDataAndTemplate>
) => {
  const t = GET_REPORT_TYPE(type);
  const action: ApiActionPayload = {
    url: `${SERVICE_URLS[SERVICE_NAME]}${GET_API_VERSION(type)}/${t}/${reportId}`,
    method: "GET",
    ...callbacks,
  };
  simpleApi(action);
  return action;
};

const saveAdditional = (
  body: { question: string; answer: string | null }[] | null,
  reportId: string,
  type: ReportType,
  callbacks: ApiCallback<any>
) => {
  if (type !== "winterReport") {
    console.warn("not supported");
  }
  const t = GET_REPORT_TYPE(type);
  const action: ApiActionPayload = {
    url: `${SERVICE_URLS[SERVICE_NAME]}${GET_API_VERSION(type)}/${t}/${reportId}/submitInfo`,
    data: { additionalQuestionList: body },
    method: "PUT",
    ...callbacks,
  };
  simpleApi(action);
  return action;
};

const getRecentDataSuggestionList = (
  organisationId: string | number,
  type: ReportType,
  callbacks: ApiCallback<any>
) => {
  const x = GET_REPORT_TYPE_WITH_ID_STRING(organisationId, type);
  const action: ApiActionPayload = {
    url: `${SERVICE_URLS[SERVICE_NAME]}${GET_API_VERSION(type)}/${x}/recent`,
    method: "GET",
    ...callbacks,
  };
  simpleApi(action);
  return action;
};

const sendPing = (reportId: string | number, type: ReportType, callbacks: ApiCallback<void>) => {
  const t = GET_REPORT_TYPE(type);
  const action: ApiActionPayload = {
    url: `${SERVICE_URLS[SERVICE_NAME]}${GET_API_VERSION(type)}/${t}/editing/${reportId}`,
    method: "GET",
    ...callbacks,
  };
  simpleApi(action);
  return action;
};

const ReportService = {
  getTemplateByOrganisationIdAndType,
  saveReport,
  getReportDataAndTemplate,
  saveAsDraft,
  // saveReportByReportId,
  saveAdditional,
  getRecentDataSuggestionList,
  sendPing,
};

export default ReportService;
