import { CurrentAndPastIndicatorStatus } from "../types/forms/general";
// import IndicatorNew from "../types/indicator-new";
import { ImportedConfigIndicator } from "../types/forms-config";
import { IndicatorRegionReport } from "../types/indicator";

export const getValueType = (item: any): string => {
  if (item.valueDataType === "options") {
    return "ARRAY";
  }
  if (item.fieldType === "duration") {
    return "DURATION";
  }
  // if (item.fieldType === "checkbox") {
  //   return "ARRAY";
  // }
  // if (item.fieldType === "dropdown" && item.config.allowMultiSelect === true) {
  //   return "ARRAY";
  // }
  if (item.fieldType === "datetime") {
    let datePartial = "";
    if (item.config.date === true) {
      datePartial += "DATE";
    }
    if (item.config.time === true) {
      datePartial += "TIME";
    }
    if (item.config.range === true) {
      datePartial += "_RANGE";
    }
    return datePartial;
  }
  // dates
  if (item.fieldType === "indicator") {
    return "INDICATOR";
  }
  if (item.valueDataType === "richtext") {
    return "RICHTEXT";
  }
  return "STRING";
};

export const getIndicator = (
  item: ImportedConfigIndicator,
  indicators: CurrentAndPastIndicatorStatus
): IndicatorRegionReport | undefined => {
  // ? if period is absent assume it's === current (backwards compatibility)
  if ((!item.valuePeriod || item.valuePeriod === "current") && indicators.current[item.indicatorId]) {
    return indicators.current[item.indicatorId];
  }
  if (indicators.past[item.indicatorId]) {
    return indicators.past[item.indicatorId][item.valuePeriod][item.normalisationCondition as string][
      item.aggregationCondition as string
    ]; //hopefully it's ID ==> period ==> normCondition ==> aggCondition
  }
  return undefined;
};
