import React from "react";
import { IndicatorFootnoteLabel } from "./indicator-footnote-label";
// ? TYPES:
import { ImportedConfigIndicator } from "../../../types/forms-config";

export const IndicatorTitle = ({
  configIndicators,
  commentFootLabelPlot,
  name,
}: {
  configIndicators: ImportedConfigIndicator[];
  commentFootLabelPlot: Record<number, string>;
  name: string;
}): JSX.Element => {
  return (
    <header className="thead">
      <div className="row">
        {configIndicators.map((option, index) => {
          const footLabel = commentFootLabelPlot[index];
          return (
            <span key={option.indicatorId + "_" + index} className="th brag-holder ta-center">
              {option.title}
              <IndicatorFootnoteLabel footLabel={footLabel} id={name + "_" + index} />
            </span>
          );
        })}
      </div>
    </header>
  );
};
